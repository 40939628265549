<template>
  <CCard>
    <CCardHeader class="d-flex align-items-center">
      <TMessage content="New order" bold />
      <div class="ml-auto">
        <SButtonCreateOrder
          color="primary"
          @click="create"
          :options="{ disabled: disabled }"
        />
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow class="mb-2">
        <CCol col="12" lg="6" class="mb-3">
          <SSelectSupplier
            @update:value="supplier_id = $event"
            @change="supplier = $event"
            label="Supplier"
            class="my-3"
          />
          <TTableAsForm
            v-if="supplier"
            :data="supplier"
            :fields="supplierFields"
            :splitLeft="5"
            class="border-primary rounded p-2 px-3"
          >
            <template #name="{ value }">
              <TMessageText :value="value" />
            </template>
            <template #address="{ value }">
              <TMessageText :value="value" />
            </template>
            <template #email="{ value }">
              <TMessageText :value="value" />
            </template>
            <template #note="{ value }">
              <TMessageText :value="value" />
            </template>
          </TTableAsForm>
        </CCol>

        <CCol col="12" lg="6" class="mb-3">
          <SSelectCurrency
            label="Currency"
            :value.sync="currency_id"
            :prepend="[]"
            class="my-3"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      supplierFields: [
        { key: "name", label: "Name" },
        { key: "address", label: "Address" },
        { key: "email", label: "Email" },
        { key: "note", label: "Note" },
      ],
      supplier_id: "",
      supplier: null,
      additional_cost: null,
      currency_id: "JPY",
      disabled: false,
    };
  },
  watch: {
    customer() {
      this.supplier = null;
    },
  },
  methods: {
    create() {
      this.disabled = true;
      let data = {
        supplier_id: this.supplier_id,
        currency_id: this.currency_id,
      };
      const dataClean = this.lodash.pickBy(data, this.lodash.identity);
      this.$store
        .dispatch("order.purchases.create", dataClean)
        .then((data) => {
          this.$router.push({ path: `${data.id}` });
        })
        .finally(() => {
          this.disabled = false;
        });
    },
  },
};
</script>
